// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-persona-tsx": () => import("./../../../src/templates/persona.tsx" /* webpackChunkName: "component---src-templates-persona-tsx" */),
  "component---src-templates-personas-tsx": () => import("./../../../src/templates/personas.tsx" /* webpackChunkName: "component---src-templates-personas-tsx" */),
  "component---src-templates-quiz-tsx": () => import("./../../../src/templates/quiz.tsx" /* webpackChunkName: "component---src-templates-quiz-tsx" */),
  "component---src-templates-reference-tsx": () => import("./../../../src/templates/reference.tsx" /* webpackChunkName: "component---src-templates-reference-tsx" */),
  "component---src-templates-references-tsx": () => import("./../../../src/templates/references.tsx" /* webpackChunkName: "component---src-templates-references-tsx" */)
}

