// import red from "@material-ui/core/colors/red";
import { createMuiTheme } from "@material-ui/core";

const MERRIWEATHER = "MerriweatherSans-Regular";
// const CINZEL = "Cinzel-Regular";

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: [MERRIWEATHER, "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: `#210C02`, //dark background
    },
    secondary: {
      main: `#db6b33`, //orange
    },
    info: {
        main: `#33a3db`, //bluish
    },
    //   error: {
    //     main: red.A400,
    //   },
    background: {
      default: "rgba(138,61,25,1)", //dark orange
      paper: `#210C02`, //dark background
    },
    text: {
      paper: `#ffe6d9`, // light orange
      secondary: `#ffe6d9`,
    },
  },
});

export default theme;



    // primary: "#db6b33",
    // accent: "#00e0ff", // "#f1c40f",
    // background:  "#210C02",  
    // surface:   "#210C02",
    // text: "#db6b33",
    // borderColor: "#e8a07c",  //"#e08151",
    // circleBackground: "#3d5875",
    // backdrop: "#f8e4da",
    // placeholder: "#612b11"